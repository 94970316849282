import {
  ColorPreset,
  ColorScheme,
  FontWeight,
  Glyph,
  Space,
  Tag,
  TagColor,
  TagSize,
  TagVariant,
  Text,
  Tooltip,
} from "@gocardless/flux-react";
import { i18n } from "@lingui/core";
import { t, Trans } from "@lingui/macro";

const TemporarilyDisabledInstitution = () => {
  const toolTipContent = i18n._(
    t`This bank is temporarily disabled for payments. Please choose a different bank.`
  );
  return (
    <>
      <Space h={0.5} layout="inline" />
      <Tooltip placement="right-end" message={toolTipContent}>
        {(triggerProps) => {
          return (
            <Tag
              variant={TagVariant.Tinted}
              color={TagColor.Neutral}
              size={TagSize.Md}
              colorScheme={ColorScheme.OnLight}
              iconBefore={Glyph.Tooltip}
              {...triggerProps}
            >
              <Text
                weight={FontWeight.Normal}
                color={ColorPreset.TextOnLight_01}
              >
                <Trans>Temporarily Disabled</Trans>
              </Text>
            </Tag>
          );
        }}
      </Tooltip>
    </>
  );
};

export default TemporarilyDisabledInstitution;
